import React from "react";

const Footer = () => {
  return (
    <footer className="footer bg-gray-800 py-4 fixed bottom-0 w-full">
      <div className="container mx-auto flex justify-center items-center">
        <a
          href="https://www.linkedin.com/in/hamza-yaman-dev/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white mx-2 hover:text-gray-400"
        >
          LinkedIn
        </a>
        <a
          href="https://www.instagram.com/yamanhamza/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white mx-2 hover:text-gray-400"
        >
          Instagram
        </a>
        <a
          href="https://github.com/HaHamZaDe"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white mx-2 hover:text-gray-400"
        >
          GitHub
        </a>
      </div>
    </footer>
  );
};

export default Footer;
