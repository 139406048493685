import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ActivityCard } from "./ActivityCard";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const formRef = useRef(null);

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      await emailjs.sendForm(
        "service_8vlg9iw",
        "template_13cx6xq",
        formRef.current,
        {
          publicKey: "OhVPtHxDW2WYFJJ1f",
        }
      );
      console.log("SUCCESS!");

      setName("");
      setSurname("");
      setEmail("");
      setSubject("");
      setMessage("");
    } catch (error) {
      console.log("FAILED...", error.text);
    }
  };

  return (
    <div className="min-h-screen pt-16 flex flex-col items-center justify-center bg-gray-100 rounded-3xl mb-16">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-7xl w-full px-6">
        <div className="col-span-1 md:col-span-1 bg-white p-6 rounded-lg shadow-lg">
          <h1 className="text-3xl font-semibold mb-4 text-gray-800">Contact</h1>
          <p>
            To get in touch with me, please fill out the form on the side. If
            you have any questions, comments, or suggestions, I would be happy
            to assist you. Additionally, you can attend my listed events and
            enjoy a pleasant evening.
          </p>
          <br />
          <p>
            Furthermore, you can also reach me through the LinkedIn, Instagram,
            and GitHub links below. Through these platforms, you can follow my
            projects, learn more about my professional background, and interact
            with me on social media.
          </p>
        </div>

        <div className="col-span-1 md:col-span-1 bg-white p-6 rounded-lg shadow-lg">
          <h1 className="text-3xl font-semibold mb-4 text-gray-800">
            Activity
          </h1>
          <div className="grid grid-cols-1 gap-4 h-96 overflow-y-scroll">
            <ActivityCard
              dayn="30"
              day="Thursday"
              month="June"
              time="09:00 PM"
              goto="https://mobilet.com/tr/event/bi-takim-olaylar-30598/"
            />
            {/* Ekstra kartlar ekleyin */}
          </div>
        </div>

        <div className="col-span-1 md:col-span-1 bg-white p-6 rounded-lg shadow-lg">
          <h1 className="text-3xl font-semibold mb-4 text-gray-800">
            Quick Contact Form
          </h1>
          <form ref={formRef} onSubmit={sendEmail} className="w-full">
            <div className="flex flex-col md:flex-row mb-4">
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full md:w-1/2 px-4 py-2 mb-4 md:mb-0 md:mr-2 border border-gray-800 rounded-lg focus:outline-none focus:border-blue-500"
              />
              <input
                type="text"
                name="surname"
                placeholder="Surname"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                className="w-full md:w-1/2 px-4 py-2 md:ml-2 border border-gray-800 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4">
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 border border-gray-800 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                name="subject"
                placeholder="Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="w-full px-4 py-2 border border-gray-800 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
            <textarea
              name="message"
              value={message}
              placeholder="Write your message here..."
              onChange={(e) => setMessage(e.target.value)}
              rows={5}
              style={{ resize: "none" }}
              className="w-full px-4 py-2 mb-4 border border-gray-800 rounded-lg focus:outline-none focus:border-blue-500"
            />
            <button
              type="submit"
              className="w-full px-4 py-2 bg-blue-500 text-white rounded-lg focus:outline-none focus:bg-blue-600"
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
