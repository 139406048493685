import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Element } from "react-scroll";
import Header from "./components/Header";
import Home from "./components/Home";
import CodePage from "./components/CodePage";
import EngineeringPage from "./components/EngineeringPage";
import ComedyPage from "./components/ComedyPage";
import Footer from "./components/Footer";
import ContactUs from "./components/ContactUs";

const App = () => {
  return (
    <Router>
      <div className="App  mx-auto p-4 bg-[#5447b6] min-w-screen ">
        <div className="my-auto fixed inset-x-0 top-0  bg-gray-800">
          <div className="container mx-auto">
            <Header />
          </div>
        </div>
        <div className="content-container  mx-auto">
          <Element name="homeSection">
            <Home />
          </Element>
          <Element name="codeSection">
            <CodePage />
          </Element>
          <Element name="engineeringSection">
            <EngineeringPage />
          </Element>
          <Element name="comedySection">
            <ComedyPage />
          </Element>
          <Element name="contactSection">
            <ContactUs />
          </Element>
        </div>
      </div>

      <Footer />
    </Router>
  );
};

export default App;
