// components/Header.js
import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import {
  MdOutlineCode,
  MdEngineering,
  MdOutlineTheaterComedy,
  MdOutlineHome,
  MdOutlineContactMail,
  MdMenu,
  MdClose,
} from "react-icons/md";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="header-container flex justify-between items-center mb-6 relative">
      <h1 className="text-3xl font-semibold mt-6 text-white">Hamza Yaman</h1>
      <div className="hidden md:flex items-center space-x-2 mt-6">
        {["home", "code", "engineering", "comedy", "contact"].map((section) => (
          <ScrollLink
            key={section}
            to={`${section}Section`}
            smooth={true}
            duration={500}
          >
            {getIconForSection(section)}
          </ScrollLink>
        ))}
      </div>
      <div className="md:hidden mt-6">
        <button onClick={toggleMenu} className="text-white text-3xl">
          {menuOpen ? <MdClose /> : <MdMenu />}
        </button>
      </div>
      {menuOpen && (
        <div className="absolute top-16 left-0 w-full bg-gray-800 flex flex-col items-center md:hidden">
          {["home", "code", "engineering", "comedy", "contact"].map(
            (section) => (
              <ScrollLink
                key={section}
                to={`${section}Section`}
                smooth={true}
                duration={500}
                onClick={toggleMenu}
                className="py-2 text-white text-2xl"
              >
                {getIconForSection(section)}
              </ScrollLink>
            )
          )}
        </div>
      )}
    </div>
  );
};

const getIconForSection = (section) => {
  switch (section) {
    case "home":
      return <MdOutlineHome className="text-3xl m-2 text-white" />;
    case "code":
      return <MdOutlineCode className="text-3xl m-2 text-white" />;
    case "engineering":
      return <MdEngineering className="text-3xl m-2 text-white" />;
    case "comedy":
      return <MdOutlineTheaterComedy className="text-3xl m-2 text-white" />;
    case "contact":
      return <MdOutlineContactMail className="text-3xl m-2 text-white" />;
    default:
      return null;
  }
};

export default Header;
