import React from "react";

const CodePage = () => {
  return (
    <div className="min-h-screen pt-16 flex flex-col items-center justify-center mb-4 bg-gray-100 rounded-3xl">
      <h1 className="text-xl font-semibold">Code</h1>
      <p className="text-lg text-center">
        Thank you for your patience, content will be loaded soon.
      </p>
    </div>
  );
};

export default CodePage;
