import React from "react";
import activity from "../assets/bi-takim-olaylar.png";
export const ActivityCard = ({ dayn, day, month, time, goto }) => {
  return (
    <a href={goto} target="_blank" rel="noopener noreferrer">
      <div className="flex justify-between border  p-6  rounded-lg shadow-lg bg-gray-300">
        <div className="flex flex-col items-center justify-center text-center border-r border-gray-200 pr-6 mr-6">
          <p className="text-6xl font-bold text-[#5447b6]">{dayn}</p>
          <p className="text-lg font-medium text-gray-700">{month}</p>
          <p className="text-md text-gray-600">{day}</p>
          <p className="text-md text-gray-600">{time}</p>
        </div>
        <div className="flex">
          <img
            src={activity}
            alt="Etkinlik Görseli"
            className=" w-40 object-contain rounded-lg"
          />
        </div>
      </div>
    </a>
  );
};
