// Home.jsx

import React from "react";
import ProfilResmi from "./../assets/HamzaYAMAN-Profil.jpg";

const Home = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-center h-screen">
      <div className="mx-8">
        <img
          src={ProfilResmi}
          alt="Profil Resmi"
          className="rounded-full w-60 h-60"
        />
      </div>
      <div className="mt-8 md:mt-0">
        <h1 className="text-3xl font-semibold mb-4 text-gray-100">
          I'm Hamza Yaman
        </h1>
        <p className="text-lg text-gray-100">Front-End Developer 🚀</p>
        <p className="text-lg text-gray-100">Mechanical Engineer 👷</p>
        <p className="text-lg text-gray-100">
          Improvisational Theater Actor 🎭
        </p>
      </div>
    </div>
  );
};

export default Home;
